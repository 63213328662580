import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../Section'

const PrimaryLink = styled(BaseButton)`		
	padding-left:0 !important;
	padding-right:0 !important;
	color: #B30000;	
	& .icon{
		> svg{
			fill: #B30000;
			path{
				fill: #B30000;
			}
		}
	}
	&:hover{
		color: #0B619B;
		& .icon{
			> svg{
				fill: #0B619B;
				path{
					fill: #0B619B;
				}

			}
		}
	}
`

const PrimaryLinkButton = (props) => {
	const { icon, text, textBefore } = props;
	return(
		<PrimaryLink className='btn'>
			{textBefore ?
                (
                    <>
                    <span className='text'>{textBefore}</span>
                    <span className='icon'>{icon}</span>
                    </>
                ):(
                    <>
                    <span className='icon'>{icon}</span>
                    <span className='text'>{text}</span>
                    </>
                )
            }
		</PrimaryLink>
	)
}

export default PrimaryLinkButton