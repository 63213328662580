import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const CardDefault = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  text-align: center;
`
const CardDefaultTitle = styled.h3`
  font-weight: 900;
  margin: 0;
  font-size: 20px;
  line-height: 30px;
  a {
    color: #000;
    &:hover,
    &:focus {
      color: #0b619b;
    }
  }
`
const ProductSku = styled.div`
  font-weight: 500;
  color: #999;
`
const CardDefaultFigure = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;

  .gatsby-image-wrapper {
    width: 100%;
    position: relative;
    z-index: 1;
  }
`
const CardDefaultBody = styled.div`
  position: relative;
  width: 100%;
  padding: 15px 0;
`

const RelatedProductCard = ({ data }) => {
  return (
    <CardDefault className="card">
      {data.productImages && (
        <CardDefaultFigure className="card-img">
          <GatsbyImage
            image={data.productImages.gatsbyImageData}
            alt={data.productImages.title}
          />
        </CardDefaultFigure>
      )}
      <CardDefaultBody className="card-body">
        <ProductSku>Product SKU : {data.productSku}</ProductSku>
        <Link to={data.url}>
          <CardDefaultTitle className="card-title">
            {data.productName}
          </CardDefaultTitle>
        </Link>
      </CardDefaultBody>
    </CardDefault>
  )
}

export default RelatedProductCard
